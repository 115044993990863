import React from 'react';
//Components
import Footer from './footer';
import Icon from '../global/icon';
import Slider from '../slider/slider';
//Material UI
import Button from '@material-ui/core/Button';
import location from '../../images/location.svg';
import whatsapp from '../../images/whatsapp.svg';
import mail from '../../images/mail.svg';
import Grid from '@material-ui/core/Grid';
//Styles
import promotionsStyles from './promotions.module.css';

export default ({ background, contact, images, handleMore, logo }) => (
  <div
    className={promotionsStyles.root}
    style={{ background: `url(${background})`, backgroundSize: 'cover' }}
  >
    <Grid
      className={promotionsStyles.grid}
      container
      direction="column"
      justify="space-between"
    >
      <Grid item>
        <img className={promotionsStyles.logo} src={logo} alt="" />
        <Grid container justify="center">
          <Button
            color="primary"
            onClick={handleMore}
            variant="contained"
            size="small"
          >
            MÁS INFORMACIÓN
          </Button>
        </Grid>
      </Grid>
      <Grid className={promotionsStyles.promotion} item>
        <Slider isMobile slides={images} />
      </Grid>
      <Grid item>
        <Grid container justify="flex-end">
          <Grid item>
            <a
              href={`https://wa.me/${contact.whatsapp}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon Icon={whatsapp} />
            </a>
          </Grid>
          <Grid item>
            <a
              href={contact.location}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon Icon={location} />
            </a>
          </Grid>
          <Grid item>
            <a
              href={`mailto:${contact.email}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon Icon={mail} />
            </a>
          </Grid>
        </Grid>
        <Footer
          button="MÁS INFORMACIÓN"
          contact={contact}
          handleClick={handleMore}
        />
      </Grid>
    </Grid>
  </div>
);
