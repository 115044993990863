import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
//Components
import Input from '../global/input';
import Footer from './footer';
//Material UI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
//Stles
import formStyles from './form.module.css';

const MORE_INFO = gql`
  mutation(
    $branch: String
    $email: String
    $msg: String
    $name: String
    $phoneNumber: String
    $site: String
    $to: String
  ) {
    moreInfo(
      branch: $branch
      email: $email
      msg: $msg
      name: $name
      phoneNumber: $phoneNumber
      site: $site
      to: $to
    )
  }
`;

export default ({ background, brand, contact, handleClose, logo }) => (
  <div
    className={formStyles.root}
    style={{ background: `url(${background})`, backgroundSize: 'cover' }}
  >
    <Grid
      className="layout_fullheight"
      container
      direction="column"
      justify="space-between"
    >
      <Grid item>
        <Header logo={logo} />
      </Grid>
      <Grid item>
        <Body brand={brand} contact={contact} handleClose={handleClose} />
      </Grid>
      <Grid item>
        <Footer
          contact={contact}
          logo={logo}
          button="VOLVER"
          handleClick={handleClose}
        />
      </Grid>
    </Grid>
  </div>
);

const Header = ({ logo }) => (
  <Grid
    className={formStyles.header}
    alignItems="center"
    container
    justify="center"
  >
    <Grid className={formStyles.logo_container} item xs={12}>
      <img className={formStyles.logo} src={logo} alt="logo" />
    </Grid>
    <Grid item xs={4}>
      <span className={formStyles.title}>+ info</span>
    </Grid>
    <Grid item xs={8}>
      <span className={formStyles.subtitle}>
        Bríndanos tus datos y conoce más de nuestras promociones
      </span>
    </Grid>
  </Grid>
);

const Body = ({ brand, contact, handleClose }) => {
  const [email, setEmail] = React.useState('');
  const [msg, setMsg] = React.useState('');
  const [name, setName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [moreInfo] = useMutation(MORE_INFO);

  const handleClick = () => {
    if (email && msg && name && phoneNumber) {
      moreInfo({
        variables: {
          branch: brand.branch,
          email,
          msg,
          name,
          phoneNumber,
          site: brand.site,
          to: contact.email,
        },
      });
      alert('Enviado');
      handleClose();
    } else {
      alert('Llena todos los campos');
    }
  };
  return (
    <Grid alignItems="center" container justify="center" spacing={1}>
      <Grid item xs={10}>
        <Input
          fullWidth
          label="Nombre Completo"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </Grid>
      <Grid item xs={10}>
        <Input
          fullWidth
          label="Whatsapp"
          onChange={(e) => setPhoneNumber(e.target.value)}
          value={phoneNumber}
        />
      </Grid>

      <Grid item xs={10}>
        <Input
          fullWidth
          label="Correo Electrónico"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </Grid>

      <Grid item xs={10}>
        <Input
          fullWidth
          label="Promoción de Interés"
          onChange={(e) => setMsg(e.target.value)}
          value={msg}
        />
      </Grid>

      <Grid className={formStyles.button} item xs={10}>
        <Button
          color="primary"
          onClick={handleClick}
          size="small"
          variant="contained"
        >
          ENVIAR
        </Button>
      </Grid>
    </Grid>
  );
};
