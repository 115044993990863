import React from 'react';
//Img
import fb from '../../images/fb.svg';
import ig from '../../images/ig.svg';
import line from '../../images/line.svg';
//Material UI
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    alignItems: 'center',
    borderRadius: '11.5px',
    backgroundColor: '#ffffff',
    color: '#000',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '18px',
    fontWeight: 'bold',
    height: '25px',
    justifyContent: 'center',
    padding: '0.5rem',
    width: '230px',
  },
  container: {
    color: '#ffffff',
    background: theme.palette.primary.main,
    fontSize: '11px',
    padding: theme.spacing(1),
    margin: '0.5rem 0 1rem 0',
    textAlign: 'right',
    width: '100%'
  },
  icons: {
    alignContent: 'center',
    display: 'flex',
    marginRight: '5px'
  },
  line: {
    margin: '0 5px',
    height: '25px',
    width: '4px'
  },
  social: {
    height: '100%',
    width: '25px'
  }
}));

export default ({button, contact, handleClick}) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" className={classes.container} justify="space-between" spacing={1}>
      <Grid item>
        <div
          className={classes.button}
          onClick={handleClick}
          onKeyDown={handleClick}
          role="button"
          tabIndex="0"
        >
          <span>{button}</span>
        </div>
      </Grid>
      <Grid item>
        <Grid className={classes.icons} item>
          <a
            href={contact.facebook}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className={classes.social} src={fb} alt=""/>
          </a>
          <img className={classes.line} src={line} alt=""/>
          <a
            href={contact.instagram}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className={classes.social} src={ig} alt=""/>
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};
