import React, { useState } from 'react';
//Components
import Form from '../components/mobile/form';
import Promotions from '../components/mobile/promotions';
import Layout from '../components/layout';
//Material UI
import Dialog from '@material-ui/core/Dialog';

export default function Mobile({ pageContext }) {
  const [open, setOpen] = useState(false);
  const images = [];
  pageContext.buttons.map((cat) =>
    pageContext.slides[cat.value].mobile.map((slide) => images.push(slide))
  );
  console.log();
  const handleMore = () =>
    pageContext.seller && window
      ? window.open(`tel:${pageContext.seller.phoneNumber}`)
      : setOpen(true);
  return (
    <Layout>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <Form
          background={pageContext.brand.background.desktop}
          brand={pageContext.brand}
          contact={pageContext.contact}
          handleClose={() => setOpen(false)}
          logo={pageContext.brand.logos.form}
        />
      </Dialog>
      <Promotions
        background={pageContext.brand.background.desktop}
        categories={pageContext.buttons}
        contact={pageContext.contact}
        handleMore={handleMore}
        images={images}
        logo={pageContext.brand.logos.form}
      />
    </Layout>
  );
}
